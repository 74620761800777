import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {NbAuthService} from '@nebular/auth';
import {switchMap} from 'rxjs/operators';
import {JWTTokenService} from '@app/jbpm/service/JWTTokenService';

@Injectable()
export class RequestInterceptor implements HttpInterceptor, OnInit, OnDestroy {

  private static authUrl: string = 'login';


  private destroy$: Subject<void> = new Subject<void>();

  constructor(private authService: NbAuthService, private jwtTnService: JWTTokenService ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   if ( request.url.match(RequestInterceptor.authUrl) /* || request.url.match(RequestInterceptor.be) */) {

     const username = request.body?.username;
     const password = request.body?.password;

     request = request.clone( {
          setParams: {'username' :  username , 'password' : password },
     });
      return next.handle(request);
   }
    return next.handle(request);

    /*
        return this.authService.isAuthenticatedOrRefresh()
          .pipe(
            switchMap(authenticated => {

              console.log('authenticated **********8 ');
              console.log( authenticated );

              if (authenticated) {
                return this.authService.getToken().pipe(
                  switchMap(token => {
                    const JWT = `Bearer ${token.getValue()}`;
                    request = request.clone({
                      setHeaders: {
                        'Authorization': JWT,
                        'Accept': 'application/json',
                      },
                    });
                    return next.handle(request);
                  }),
                );
              } else {
                return next.handle(request);
              }
            }),
          );
        */

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
